import { IStringUtilities } from './IStringUtilities';

export class StringUtilities implements IStringUtilities  {

    public uriFormatString(textToConvert: string): string {
        if (!textToConvert) {
            return '';
        }

        let uriFormattedText = '';
        uriFormattedText = textToConvert.toLowerCase();
        uriFormattedText = uriFormattedText.replace(/ /g, '-');
        uriFormattedText = uriFormattedText.replace(/æ/g, 'ae');
        uriFormattedText = uriFormattedText.replace(/å/g, 'aa');
        uriFormattedText = uriFormattedText.replace(/ø/g, 'oe');

        return encodeURI(uriFormattedText);
    }
}
