import { IMetadataService } from './IMetadataService';
import { ISettingsDataservice } from '../dataservices/ISettingsDataservice';

export class MetadataService implements IMetadataService  {

    private _settingsDataservice: ISettingsDataservice;

    constructor(settingsDataservice: ISettingsDataservice) {
        this._settingsDataservice = settingsDataservice;
        this.setDescription(this._settingsDataservice.description);
    }

    public setTitle(title: string): void {
        document.title = title;
    }

    public setDescription(description: string): void {
        const metaDescripton = document.querySelector('meta[name="description"]');
        if (metaDescripton) {
            if (description) {
                metaDescripton.setAttribute('content', description);
            } else if (this._settingsDataservice.description) {
                metaDescripton.setAttribute('content', this._settingsDataservice.description);
            }
        }
    }

    public setFaviconUrl(faviconUrl: string): void {
        if (!faviconUrl) {
            return;
        }

        const link = document.querySelector<HTMLLinkElement>(
            'link[rel*="icon"]') || document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'icon';
        link.href = faviconUrl;
        document.getElementsByTagName('head')[0].appendChild(link);
    }

    public setTouchIcon(touchIconUrl: string): void {
        if (!touchIconUrl) {
            return;
        }

        const link = document.querySelector<HTMLLinkElement>('link[rel="apple-touch-icon"]') || document.createElement('link');
        link.rel = 'apple-touch-icon';
        link.href = touchIconUrl;
        document.getElementsByTagName('head')[0].appendChild(link);
    }
}
