import firebase from 'firebase/app';
import 'firebase/firestore';
import { ISettingsDataservice as ISettingsDataservice } from './ISettingsDataservice';
import { IImageDataservice } from './IImageDataservice';
import { ImageRef } from '../entities/ImageRef';

export class SettingsDataservice implements ISettingsDataservice {

    private _settingsRef: firebase.firestore.CollectionReference;
    private _settingsDoc: firebase.firestore.DocumentReference;
    private _shopName: string;
    private _description: string;
    private _logo: ImageRef | undefined;
    private _header: string;
    private _subheader: string;
    private _imageDataservice: IImageDataservice;

    constructor(fireStore: firebase.firestore.Firestore, imageDataservice: IImageDataservice ) {

        this._settingsRef = fireStore.collection('settings');
        this._settingsDoc = this._settingsRef.doc('shopsettings');
        this._shopName = '';
        this._description = '';
        this._logo = undefined;
        this._header = '';
        this._subheader = '';
        this._imageDataservice = imageDataservice;

        this._settingsDoc.onSnapshot((doc) => {
          if (doc.exists && doc.data()) {
            const data = doc.data();
            if (data) {
              this._shopName = data.name;
              this._description = data.description;
              this._logo = new ImageRef(
                data.smallLogoId,
                data.smallLogoUrl,
                data.mediumLogoId,
                data.mediumLogoUrl,
                data.smallLogoNoAlphaId,
                data.smallLogoNoAlphaUrl);
              this._header = data.header;
              this._subheader = data.subheader;
            }
          }
        });
    }

    public setShopname(shopName: string): void {
      this._settingsDoc.set({name: shopName}, { merge: true });
    }

    public setDescription(description: string): void {
      this._settingsDoc.set({description}, { merge: true });
    }

    public setHeader(header: string): void {
      this._settingsDoc.set({header}, { merge: true });
    }

    public setSubheader(subheader: string): void {
      this._settingsDoc.set({subheader}, { merge: true });
    }

    public get shopName(): string {
      return this._shopName;
    }

    public get description(): string {
      return this._description;
    }

    public get faviconUrl(): string {
      if (!this._logo) {
        return '';
      }

      return this._logo.smallImagedownloadUrl;
    }

    public get touchIconUrl(): string {
      if (!this._logo) {
        return '';
      }

      return this._logo.smallImageNoAlphaDownloadUrl;
    }

    public get header(): string {
      return this._header;
    }

    public get subheader(): string {
      return this._subheader;
    }

    public updateFavicon(file: File): Promise<void> {

      return new Promise((resolve) => {

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.addEventListener('load', () => {
          const dataUrl = reader.result as string;
          this._imageDataservice.addImage(file.name, dataUrl).then(
            (result) => {
              this._settingsDoc.set({
                smallLogoId: result.smallImageId,
                smallLogoUrl: result.smallImagedownloadUrl,
                mediumLogoId: result.mediumImageId,
                mediumLogoUrl: result.mediumImagedownloadUrl,
                smallLogoNoAlphaId: result.smallImageNoAlphaId,
                smallLogoNoAlphaUrl: result.smallImageNoAlphaDownloadUrl,
              }, { merge: true }
              ).then(() => {
                  resolve();
                });
              }
          );
        });
      });
    }

    public deleteFavicon(): void {
      if (!this._logo) {
        return;
      }

      this._imageDataservice.deleteImage(this._logo).then(() => {
        this._settingsDoc.set({
          smallLogoId: '',
          smallLogoUrl: '',
          mediumLogoId: '',
          mediumLogoUrl: '',
          smallLogoNoAlphaId: '',
          smallLogoNoAlphaUrl: ''
        },
        { merge: true });
      });
    }
}
