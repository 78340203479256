


































































































import Vue from 'vue';
import { ISettingsDataservice } from '../../shared/src/dataservices/ISettingsDataservice';
import { IMetadataService } from '../../shared/src/services/IMetadataService';
import { CompositionRoot } from './CompositionRoot';
import { IContactDataservice } from '../../shared/src/dataservices/IContactDataservice';
import { ICategoryDataservice } from '../../shared/src/dataservices/ICategoryDataservice';
import { IProductDataservice } from '../../shared/src/dataservices/IProductDataservice';
import { IImageDataservice } from '../../shared/src/dataservices/IImageDataservice';

export default Vue.extend({
  name: 'App',
  provide: {
    settingsDataservice: Vue.observable(CompositionRoot.getInstance().settingsDataservice) as ISettingsDataservice,
    contactDataservice: Vue.observable(CompositionRoot.getInstance().contactDataservice) as IContactDataservice,
    categoryDataservice: Vue.observable(CompositionRoot.getInstance().categoryDataservice) as ICategoryDataservice,
    productDataservice: Vue.observable(CompositionRoot.getInstance().productDataservice) as IProductDataservice,
    metadataService: Vue.observable(CompositionRoot.getInstance().metadataService) as IMetadataService,
    imageDataservice: Vue.observable(CompositionRoot.getInstance().imageDataservice) as IImageDataservice
  },
  data: () => ({
    settingsDataservice: CompositionRoot.getInstance().settingsDataservice as ISettingsDataservice,
    contactDataservice: CompositionRoot.getInstance().contactDataservice as IContactDataservice,
    metadataService: CompositionRoot.getInstance().metadataService as IMetadataService
  }),
  computed: {
    subheaderExists(): boolean {
      if (!this.settingsDataservice.subheader ) {
        return false;
      }

      if (this.settingsDataservice.subheader.trim() === '') {
        return false;
      }

      return true;
    },
    faviconUrl(): string {
      return this.settingsDataservice.faviconUrl;
    },
    touchIconUrl(): string {
      return this.settingsDataservice.touchIconUrl;
    },
  },
  watch: {
    faviconUrl: {
      immediate: true,
      handler(newValue): void {
        this.metadataService.setFaviconUrl(newValue);
      },
    },
    touchIconUrl: {
      immediate: true,
      handler(newValue): void {
        this.metadataService.setTouchIcon(newValue);
      },
    }
  },
  methods: {
    goHome(): void {
      if (this.$router.currentRoute.name !== 'home') {
        this.$router.push({ name: 'home'});
      }
    }
  }
});
