import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('./views/Home.vue')
    },
    {
      path: '/contact',
      name: 'contact',
      component: () => import('./views/Contact.vue')
    },
    {
      path: '/:categoryNameUriFormatted',
      name: 'category',
      component: () => import('./views/Category.vue'),
    },
    {
      path: '/:categoryNameUriFormatted/:productNameUriFormatted/:productId',
      name: 'product',
      component: () => import('./views/Product.vue')
    }
  ]
});

export default router;
