import firebase from 'firebase/app';
import 'firebase/firestore';
import { IImageDataservice } from './IImageDataservice';
import { IContactDataservice } from './IContactDataservice';
import { ImageRef } from '../entities/ImageRef';

export class ContactDataservice implements IContactDataservice {

    private _settingsRef: firebase.firestore.CollectionReference;
    private _contactDoc: firebase.firestore.DocumentReference;
    private _contactText: string;
    private _contactImage: ImageRef | undefined;
    private _imageDataservice: IImageDataservice;
    private _facebookLink: string;
    private _instagramLink: string;
    private _email: string;
    private _contactPageActive: boolean;

    constructor(fireStore: firebase.firestore.Firestore, imageDataservice: IImageDataservice ) {

        this._settingsRef = fireStore.collection('settings');
        this._contactDoc = this._settingsRef.doc('contactsettings');
        this._imageDataservice = imageDataservice;

        this._contactText = '';
        this._contactImage = undefined;
        this._facebookLink = '';
        this._instagramLink = '';
        this._email = '';
        this._contactPageActive = false;

        this._contactDoc.onSnapshot((doc) => {
          if (doc.exists && doc.data()) {
            const data = doc.data();
            if (data) {
              this._contactText = data.contactText;
              this._contactImage = new ImageRef(
                data.smallContactImageId,
                data.smallContactImageUrl,
                data.mediumContactImageId,
                data.mediumContactImageUrl,
                data.smallContactImageNoAlphaId,
                data.smallContactImageNoAlphaUrl);
              this._facebookLink = data.facebookLink;
              this._instagramLink = data.instagramLink;
              this._email = data.email;
              this._contactPageActive = data.contactPageActive;
            }
          }
        });
    }

    public get contactImageUrl(): string {
      if (!this._contactImage) {
        return '';
      }

      return this._contactImage?.mediumImagedownloadUrl;
    }

    public get contactText(): string {
      return this._contactText;
    }

    public set contactText(contactText: string) {
      this._contactDoc.set({contactText}, { merge: true });
    }

    public get facebookLink(): string {
      return this._facebookLink;
    }

    public set facebookLink(facebookLink: string) {
      this._contactDoc.set({facebookLink}, { merge: true });
    }

    public get email(): string {
      return this._email;
    }

    public set email(email: string) {
      this._contactDoc.set({email}, { merge: true });
    }

    public get instagramLink(): string {
      return this._instagramLink;
    }

    public set instagramLink(instagramLink: string) {
      this._contactDoc.set({instagramLink}, { merge: true });
    }

    public get contactPageActive(): boolean {
      return this._contactPageActive;
    }

    public set contactPageActive(contactPageActive: boolean) {
      this._contactDoc.set({contactPageActive}, { merge: true });
    }

    public setContactImage(file: File): Promise<void> {

      return new Promise((resolve) => {

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.addEventListener('load', () => {
          const dataUrl = reader.result as string;
          this._imageDataservice.addImage(file.name, dataUrl).then(
            (result) => {
              this._contactDoc.set({
                smallContactImageId: result.smallImageId,
                smallContactImageUrl: result.smallImagedownloadUrl,
                mediumContactImageId: result.mediumImageId,
                mediumContactImageUrl: result.mediumImagedownloadUrl
              }, { merge: true }
              ).then(() => {
                  resolve();
                });
              }
          );
        });
      });
    }

    public deleteContactImage(): void {
      if (!this._contactImage) {
        return;
      }

      this._imageDataservice.deleteImage(this._contactImage).then(() => {
        this._contactDoc.set({
          contactImageId: '',
          contactImageUrl: ''
        },
        { merge: true });
      });
    }
}
