import { CategoryStatus } from './CategoryStatus';
import { ImageRef } from './ImageRef';

export class Category {

    private _id: string;
    private _name: string;
    private _image: ImageRef | undefined;
    private _nameUriformatted: string;
    private _sortIndex: number;
    private _createdTimeUnixEpochUTCSec: number;
    private _modifiedTimeUnixEpochUTCSec: number | undefined;
    private _status: CategoryStatus;
    private _description: string;

    constructor(
        id: string,
        name: string,
        nameUriformatted: string,
        image: ImageRef,
        sortIndex: number,
        createdTimeUnixEpochUTCSec: number,
        modifiedTimeUnixEpochUTCSec: number | undefined,
        categoryStatus: CategoryStatus,
        description: string) {

        this._name = name;
        this._id = id;
        this._image = image;
        this._nameUriformatted = nameUriformatted;
        this._sortIndex = sortIndex;
        this._createdTimeUnixEpochUTCSec = createdTimeUnixEpochUTCSec;
        this._modifiedTimeUnixEpochUTCSec = modifiedTimeUnixEpochUTCSec;
        this._status = categoryStatus;
        this._description = description;
    }

    public get id(): string {
        return this._id;
    }

    public get nameUriformatted(): string {
        return this._nameUriformatted;
    }

    public get name(): string {
        return this._name;
    }

    public get description(): string {
        return this._description;
    }

    public get image(): ImageRef | undefined {
        return this._image;
    }

    public get sortIndex(): number {
        return this._sortIndex;
    }

    public get createdTimeLocalized(): string | undefined {
        if (!this._createdTimeUnixEpochUTCSec) {
            return undefined;
        }

        const date = new Date(0);
        date.setUTCSeconds(this._createdTimeUnixEpochUTCSec);

        return date.toLocaleDateString();
    }

    public get modifiedTimeLocalized(): string | undefined {
        if (!this._modifiedTimeUnixEpochUTCSec) {
            return undefined;
        }

        const date = new Date(0);
        date.setUTCSeconds(this._modifiedTimeUnixEpochUTCSec);

        return date.toLocaleDateString();
    }

    public get status(): CategoryStatus {
        return this._status;
    }
}
