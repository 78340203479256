
export class CategoryStatus {

    public static INACTIVE: CategoryStatus = new CategoryStatus('INACTIVE', 'Inaktiv');
    public static ACTIVE: CategoryStatus = new CategoryStatus('ACTIVE', 'Aktiv');

    public static all(): CategoryStatus[]  {
        return this._allCategoryStatusValues;
    }

    public static convertToStatus(value: any): CategoryStatus {
        switch (value) {
            case CategoryStatus.INACTIVE.id: return CategoryStatus.INACTIVE;
            case CategoryStatus.ACTIVE.id: return CategoryStatus.ACTIVE;
            default: return CategoryStatus.INACTIVE;
        }
    }

    private static _allCategoryStatusValues: CategoryStatus[] = [
        CategoryStatus.INACTIVE,
        CategoryStatus.ACTIVE];

    private _id: string;
    private _name: string;

    constructor( id: string, name: string ) {
        this._id = id;
        this._name = name;
    }

    public get id(): string {
        return this._id;
    }

    public get name(): string {
        return this._name;
    }

    public toString(): string {
        return this._id;
    }
}
