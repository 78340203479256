
export class ProductStatus {

    public static INACTIVE: ProductStatus = new ProductStatus('INACTIVE', 'Inaktiv');
    public static FOR_SALE: ProductStatus = new ProductStatus('FOR_SALE', 'Til salg');
    public static SOLD: ProductStatus = new ProductStatus('SOLD', 'Solgt');
    public static RESERVED: ProductStatus = new ProductStatus('RESERVED', 'Reserveret');

    public static all(): ProductStatus[]  {
        return this._allProductStatusValues;
    }

    public static convertToStatus(value: any): ProductStatus {
        switch (value) {
            case ProductStatus.INACTIVE.id: return ProductStatus.INACTIVE;
            case ProductStatus.FOR_SALE.id: return ProductStatus.FOR_SALE;
            case ProductStatus.SOLD.id: return ProductStatus.SOLD;
            case ProductStatus.RESERVED.id: return ProductStatus.RESERVED;
            default: return ProductStatus.INACTIVE;
        }
    }

    private static _allProductStatusValues: ProductStatus[] = [
        ProductStatus.INACTIVE,
        ProductStatus.FOR_SALE,
        ProductStatus.SOLD,
        ProductStatus.RESERVED];

    private _id: string;
    private _name: string;

    constructor( id: string, name: string ) {
        this._id = id;
        this._name = name;
    }

    public get id(): string {
        return this._id;
    }

    public get name(): string {
        return this._name;
    }

    public toString(): string {
        return this._id;
    }
}
