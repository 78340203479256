import { IImageDataservice } from './IImageDataservice';
import { IProductDataservice } from './IProductDataservice';
import { Product } from '../entities/Product';
import { ImageRef } from '../entities/ImageRef';
import { IStringUtilities } from '../services/IStringUtilities';
import { ProductStatus } from '../entities/ProductStatus';
import { ICategoryDataservice } from './ICategoryDataservice';
import firebase from 'firebase/app';
import 'firebase/firestore';

export class ProductDataService implements IProductDataservice {

    private productsRef: firebase.firestore.CollectionReference;
    private _products: Product[];
    private _imageDataservice: IImageDataservice;
    private _stringUtilities: IStringUtilities;
    private _categoryDataservice: ICategoryDataservice;

    constructor(
      fireStore: firebase.firestore.Firestore,
      imageDataservice: IImageDataservice,
      stringUtilities: IStringUtilities,
      categoryDataservice: ICategoryDataservice) {

      this._imageDataservice = imageDataservice;
      this._stringUtilities = stringUtilities;
      this._products = [];
      this.productsRef = fireStore.collection('products');
      this._categoryDataservice = categoryDataservice;

      this.initalizeFirebaseQuery();
    }

    public get products(): Product[] {
        return this._products;
    }

    public isProductMatchingSearch(product: Product, searchString: string): boolean {

      const searchStringUpper = searchString.toUpperCase();

      if (product.name && product.name.toUpperCase().includes(searchStringUpper)) {
        return true;
      }

      if (product.price && product.price.toString().toUpperCase().includes(searchStringUpper)) {
        return true;
      }

      if (product.description && product.description.toString().toUpperCase().includes(searchStringUpper)) {
        return true;
      }

      if (product.category && product.category.name.toUpperCase().includes(searchStringUpper)) {
        return true;
      }

      return false;
    }

    public getProductsWithStatus(productStatus: ProductStatus): Product[] {
      return this._products.filter((product) => product.status === productStatus);
    }

    public async addProduct(): Promise<string> {
      const productDoc = await this.productsRef.add(
        {
          createdTimeUnixEpochUTCSec: firebase.firestore.Timestamp.now().seconds,
          status: ProductStatus.INACTIVE.id,
        });
      return productDoc.id;
    }

    public deleteProduct(productId: string): Promise<void> {
      const productToDelete = this.products.find((product) => product.id === productId);

      if (!productToDelete) {
        return Promise.resolve();
      }

      productToDelete.images.forEach(async (image) => {
        await this._imageDataservice.deleteImage(image);
      });

      return this.productsRef.doc(productId).delete();
    }

    public updateProductName(productId: string, newName: string) {
      this.productsRef.doc(productId).set(
        {
          name: newName,
          modifiedTimeUnixEpochUTCSec: firebase.firestore.Timestamp.now().seconds
        },
        { merge: true });
    }

    public updateProductDescription(productId: string, description: string): void {
      this.productsRef.doc(productId).set(
        {
          description,
          modifiedTimeUnixEpochUTCSec: firebase.firestore.Timestamp.now().seconds
        },
        { merge: true });
    }

    public updateProductPrice(productId: string, newPrice: number): void {
      this.productsRef.doc(productId).set(
        {
          price: newPrice,
          modifiedTimeUnixEpochUTCSec: firebase.firestore.Timestamp.now().seconds
        },
        { merge: true });
    }

    public updateCategory(productId: string, categoryId: string | undefined): void {
      this.productsRef.doc(productId).set(
        {
          categoryId,
          modifiedTimeUnixEpochUTCSec: firebase.firestore.Timestamp.now().seconds
        },
        { merge: true });
    }

    public updateStatus(productId: string, productStatus: ProductStatus): void {
      this.productsRef.doc(productId).set(
        {
          status: productStatus.id,
          modifiedTimeUnixEpochUTCSec: firebase.firestore.Timestamp.now().seconds
        },
        { merge: true });
    }

    public updateProductImage(productId: string, file: File, imageIndex: number): Promise<void> {

      return new Promise((resolve) => {

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.addEventListener('load', () => {
          const dataUrl = reader.result as string;
          this._imageDataservice.addImage(file.name, dataUrl).then(
            (result) => {
              this.productsRef.doc(productId).set({
                  images: {
                    [imageIndex]: {
                      smallImageId: result.smallImageId,
                      smallImageUrl: result.smallImagedownloadUrl,
                      mediumImageId: result.mediumImageId,
                      mediumImageUrl: result.mediumImagedownloadUrl,
                    },
                    modifiedTimeUnixEpochUTCSec: firebase.firestore.Timestamp.now().seconds
                  },
                },
                {merge: true}).then(() => {
                  resolve();
                });
              }
          );
        });
      });
    }

    public deleteProductImageByIndex(product: Product, imageIndex: number): void {

      const imageToDelete = product.images.get(imageIndex);
      if (!imageToDelete) {
        throw new Error(`Image to delete can not be found, index: ${imageIndex}, productid: ${product.id}`);
      }

      this._imageDataservice.deleteImage(imageToDelete).then(() => {
        this.productsRef.doc(product.id).set({
          images: {
            [imageIndex]: {}
          },
          modifiedTimeUnixEpochUTCSec: firebase.firestore.Timestamp.now().seconds
        },
        {
          merge: true});
        }
      );
    }

    public getProductsForCategory(categoryId: string, states: ProductStatus[]): Product[] {
        return this._products.filter((product) => product.categoryId === categoryId &&
          states.includes(product.status));
    }

    public getProductById(productId: string): Product | undefined {
      return this._products.find((product) => product.id === productId);
    }

    public sortProductsByStatus(productsToSort: Product[]): Product[] {

      return productsToSort.slice().sort( (p1, p2) => {

        if (!p1.status && !p2.status) {
          return 0;
        }

        if (p1.status && !p2.status) {
          return -1;
        }

        if (!p1.status && p2.status) {
          return 1;
        }

        if (p1.status === p2.status) {
          return 0;
        }

        if (p1.status === ProductStatus.FOR_SALE && p2.status === ProductStatus.SOLD) {
          return -1;
        }

        if (p1.status === ProductStatus.SOLD && p2.status === ProductStatus.FOR_SALE) {
          return 1;
        }

        return 0;

      });
    }

    private initalizeFirebaseQuery(): void {
      const query: firebase.firestore.Query = this.productsRef;

      query.onSnapshot((ref) => {
        ref.docChanges().forEach(async (change) => {
          const { newIndex, oldIndex, doc, type } = change;

          if (type === 'added') {
            this._products.push(new Product(
              doc.id,
              doc.data().name,
              this._stringUtilities.uriFormatString(doc.data().name),
              doc.data().categoryId,
              this.firebaseImageArrayToHashmap(doc.data().images),
              doc.data().description,
              ProductStatus.convertToStatus(doc.data().status),
              doc.data().price,
              this._categoryDataservice.getCategoryById(doc.data().categoryId),
              doc.data().createdTimeUnixEpochUTCSec,
              doc.data().modifiedTimeUnixEpochUTCSec));
          } else if (type === 'modified') {
              const index = this.products.findIndex((product) => product.id === doc.id);
              this._products.splice(index, 1, new Product(
                doc.id,
                doc.data().name,
                this._stringUtilities.uriFormatString(doc.data().name),
                doc.data().categoryId,
                this.firebaseImageArrayToHashmap(doc.data().images),
                doc.data().description,
                ProductStatus.convertToStatus(doc.data().status),
                doc.data().price,
                this._categoryDataservice.getCategoryById(doc.data().categoryId),
                doc.data().createdTimeUnixEpochUTCSec,
                doc.data().modifiedTimeUnixEpochUTCSec));
          } else if (type === 'removed') {
            const index = this.products.findIndex((product) => product.id === doc.id);
            this._products.splice(index, 1);
          }
        });
      });
    }

    private firebaseImageArrayToHashmap( firbaseImageArray: object ): Map<number, ImageRef> {
      const imageMap = new Map<number, ImageRef>();

      if (!firbaseImageArray) {
        return imageMap;
      }

      Object.entries(firbaseImageArray).forEach(
        ([key, value]) => {
            imageMap.set(Number(key),
            new ImageRef(
              value.smallImageId,
              value.smallImageUrl,
              value.mediumImageId,
              value.mediumImageUrl,
              value.smallImageNoAlphaId,
              value.smallImageNoAlphaUrl));
        }
      );

      return imageMap;
    }
}
