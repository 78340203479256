import { ICategoryDataservice } from './ICategoryDataservice';
import { Category} from '../entities/Category';
import { IImageDataservice } from './IImageDataservice';
import { IStringUtilities } from '../services/IStringUtilities';
import { CategoryStatus } from '../entities/CategoryStatus';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { ImageRef } from '../entities/ImageRef';

export class CategoryDataService implements ICategoryDataservice {

    private categoriesRef: firebase.firestore.CollectionReference;
    private _categories: Category[];
    private _imageDataservice: IImageDataservice;
    private _stringUtilities: IStringUtilities;

    constructor(
      fireStore: firebase.firestore.Firestore,
      imageDataservice: IImageDataservice,
      stringUtilities: IStringUtilities) {

      this._imageDataservice = imageDataservice;
      this._stringUtilities = stringUtilities;
      this._categories = [];
      this.categoriesRef = fireStore.collection('categories');

      this.categoriesRef.onSnapshot((ref) => {
          ref.docChanges().forEach(async (change) => {
            const { newIndex, oldIndex, doc, type } = change;

            if (type === 'added') {
              this._categories.push(new Category(
                doc.id,
                doc.data().name,
                this._stringUtilities.uriFormatString(doc.data().name),
                new ImageRef(
                  doc.data().smallImageId,
                  doc.data().smallImageUrl,
                  doc.data().mediumImageId,
                  doc.data().mediumImageUrl,
                  doc.data().smallImageNoAlphaId,
                  doc.data().smallImageNoAlphaUrl),
                doc.data().sortIndex,
                doc.data().createdTimeUnixEpochUTCSec,
                doc.data().modifiedTimeUnixEpochUTCSec,
                CategoryStatus.convertToStatus(doc.data().status),
                doc.data().description));
            } else if (type === 'modified') {
              const index = this._categories.findIndex((category) => category.id === doc.id);
              this._categories.splice(index, 1, new Category(
                doc.id, doc.data().name,
                this._stringUtilities.uriFormatString(doc.data().name),
                new ImageRef(
                  doc.data().smallImageId,
                  doc.data().smallImageUrl,
                  doc.data().mediumImageId,
                  doc.data().mediumImageUrl,
                  doc.data().smallImageNoAlphaId,
                  doc.data().smallImageNoAlphaUrl),
                doc.data().sortIndex,
                doc.data().createdTimeUnixEpochUTCSec,
                doc.data().modifiedTimeUnixEpochUTCSec,
                CategoryStatus.convertToStatus(doc.data().status),
                doc.data().description));
            } else if (type === 'removed') {
              const index = this._categories.findIndex((category) => category.id === doc.id);
              this._categories.splice(index, 1);
            }
          });
        });
    }

    public get categories(): Category[] {
        return this._categories;
    }

    public async addCategory(): Promise<string> {
      const largestSortIndex = Math.max.apply(Math, this._categories.map((category) => {
        if (!category.sortIndex) {
          return -1;
        }
        return category.sortIndex;
      }));

      const categoryDoc = await this.categoriesRef.add({
        createdTimeUnixEpochUTCSec: firebase.firestore.Timestamp.now().seconds,
        status: CategoryStatus.INACTIVE.id,
        sortIndex : largestSortIndex + 1
      });

      return categoryDoc.id;
    }

    public deleteCategory(categoryId: string): Promise<void> {

      const categoryToDelete = this.categories.find( (category) => category.id === categoryId);

      if (!categoryToDelete) {
        return Promise.resolve();
      }

      if (categoryToDelete.image) {
        return this._imageDataservice.deleteImage(categoryToDelete.image).then(() => {
          return this.categoriesRef.doc(categoryId).delete();
          },
          () => {
            return this.categoriesRef.doc(categoryId).delete();
          }
          );
      } else {
        return this.categoriesRef.doc(categoryId).delete();
      }
    }

    public updateCategoryName(categoryId: string, newName: string): void {
      this.categoriesRef.doc(categoryId).set(
        {
          name: newName,
          modifiedTimeUnixEpochUTCSec: firebase.firestore.Timestamp.now().seconds},
          { merge: true });
    }

    public updateCategorySortIndex(categoryId: string, sortIndex: number): void {
      this.categoriesRef.doc(categoryId).set({sortIndex}, { merge: true });
    }

    public updateCategoryImage(categoryId: string, file: File): Promise<void> {

      return new Promise((resolve) => {

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.addEventListener('load', () => {
          const dataUrl = reader.result as string;
          this._imageDataservice.addImage(file.name, dataUrl).then(
            (result) => {
              this.categoriesRef.doc(categoryId).set(
                {
                  smallImageId: result.smallImageId,
                  smallImageUrl: result.smallImagedownloadUrl,
                  mediumImageId: result.mediumImageId,
                  mediumImageUrl: result.mediumImagedownloadUrl,
                  modifiedTimeUnixEpochUTCSec: firebase.firestore.Timestamp.now().seconds
                },
                { merge: true }
                ).then(() => {
                  resolve();
                });
              }
          );
        });
      });
    }

    public deleteCategoryImage(categoryId: string, image: ImageRef): void {
      this._imageDataservice.deleteImage(image).then(() => {
        this.categoriesRef.doc(categoryId).set(
          {
            imageId: '',
            imageUrl: ''
          },
          { merge: true }
        );
      });
    }

    public getCategoryByUriFormattedName(categoryNameUriFormatted: string): Category | undefined {
      return this._categories.find( (category) =>
        category.nameUriformatted.toLowerCase() === categoryNameUriFormatted.toLowerCase());
    }

    public getCategoryById(categoryId: string): Category | undefined {

      if (!categoryId) {
        return undefined;
      }

      return this._categories.find( (category) => category.id === categoryId);
    }

    public sortCategoriesBySortIndex(categoriesToSort: Category[]): Category[] {

      if (categoriesToSort.length <= 1) {
        return categoriesToSort;
      }

      return categoriesToSort.slice().sort( (c1, c2) => {

        if (!c1.sortIndex && !c2.sortIndex) {
          return 0;
        }

        if (c1.sortIndex && !c2.sortIndex) {
          return -1;
        }

        if (!c1.sortIndex && c2.sortIndex) {
          return 1;
        }

        if (c1.sortIndex === c2.sortIndex) {
          return 0;
        }

        if (c1.sortIndex < c2.sortIndex) {
          return -1;
        }

        if (c1.sortIndex > c2.sortIndex) {
          return 1;
        }

        return 0;

      });
    }

    public updateStatus(categoryId: string, categoryStatus: CategoryStatus): void {
      this.categoriesRef.doc(categoryId).set(
        {
          status: categoryStatus.id,
          modifiedTimeUnixEpochUTCSec: firebase.firestore.Timestamp.now().seconds
        },
        { merge: true });
    }

    public setDescription(categoryId: string, description: string): void {
      this.categoriesRef.doc(categoryId).set(
        {
          description,
          modifiedTimeUnixEpochUTCSec: firebase.firestore.Timestamp.now().seconds
        },
        { merge: true });
    }
}
