import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import { IImageDataservice } from './IImageDataservice';
import { ImageRef } from '../entities/ImageRef';
import { IUUIDHelperService } from '../services/IUUIDHelperService';
import heic2any from 'heic2any';

export class ImageDataservice implements IImageDataservice {

  private storageRef: firebase.storage.Reference;
  private uuidHelperService: IUUIDHelperService;

  constructor(storage: firebase.storage.Storage, uuidHelperService: IUUIDHelperService ) {
      this.storageRef = storage.ref();
      this.uuidHelperService = uuidHelperService;
  }

  public async addImage(name: string, dataUrl: string): Promise<ImageRef> {

    const metaData = {
      cacheControl: 'public,max-age=31536000',
      customMetadata: {
        originalfilename: name,
      }
    };
    let fileExtension = name.split('.').pop();
    if (fileExtension?.toUpperCase() === 'JPG') {
      fileExtension = 'jpeg';
    }

    let image: HTMLImageElement;

    if (fileExtension?.toUpperCase() === 'HEIC' ) {
      const heicBlob = this.dataURItoBlob(dataUrl);
      const jpegBlob = await heic2any({ blob: heicBlob, toType: 'image/jpeg' }) as Blob;
      image = await this.blobToImage(jpegBlob);
      fileExtension = 'jpeg';
    } else {
      image = await this.loadImage(dataUrl);
    }

    const shortSide = Math.min(image.width, image.height);
    const left = (image.width - shortSide) / 2;
    const top = (image.height - shortSide) / 2;

    // Small image
    const imageLengthSmall = this.getImageLength(image, 400);
    const uniqueFileNameSmall = `${this.uuidHelperService.getUUID()}.${fileExtension}`;
    const imagePathSmall = 'images/' + uniqueFileNameSmall;
    const imageRefSmall = this.storageRef.child(imagePathSmall);
    const canvasSmall = document.createElement('canvas');
    canvasSmall.width = imageLengthSmall;
    canvasSmall.height = imageLengthSmall;
    const contextSmall = canvasSmall.getContext('2d');
    contextSmall?.drawImage(image, left, top, shortSide, shortSide, 0, 0, canvasSmall.width, canvasSmall.height);
    const srcEncodedSmall = contextSmall?.canvas.toDataURL('image/' + fileExtension);
    if (srcEncodedSmall) {
      const blob = this.dataURItoBlob(srcEncodedSmall);
      await imageRefSmall.put(blob, metaData);
    }
    const downloadURLSmall = await this.getImageUrl(imagePathSmall);

    // Small image no alpha
    const imageLengthSmallNoAlpha = this.getImageLength(image, 400);
    const uniqueFileNameSmallNoAlpha = `${this.uuidHelperService.getUUID()}.${fileExtension}`;
    const imagePathSmallNoAlpha = 'images/' + uniqueFileNameSmallNoAlpha;
    const imageRefSmallNoAlpha = this.storageRef.child(imagePathSmallNoAlpha);
    const canvasSmallNoAlpha = document.createElement('canvas');
    canvasSmallNoAlpha.width = imageLengthSmallNoAlpha;
    canvasSmallNoAlpha.height = imageLengthSmallNoAlpha;
    const contextSmallNoAlpha = canvasSmallNoAlpha.getContext('2d');
    contextSmallNoAlpha?.drawImage(
      image,
      left,
      top,
      shortSide,
      shortSide,
      0,
      0,
      canvasSmallNoAlpha.width,
      canvasSmallNoAlpha.height);
    contextSmallNoAlpha!.globalAlpha = 1;
    contextSmallNoAlpha!.setTransform(1, 0, 0, 1, 0, 0);
    contextSmallNoAlpha!.filter = 'none';
    contextSmallNoAlpha!.globalCompositeOperation = 'destination-over';
    contextSmallNoAlpha!.fillStyle = 'white';
    contextSmallNoAlpha!.fillRect(0, 0, contextSmallNoAlpha!.canvas.width, contextSmallNoAlpha!.canvas.height);

    const srcEncodedSmallNoAlpha = contextSmallNoAlpha?.canvas.toDataURL('image/' + fileExtension);
    if (srcEncodedSmallNoAlpha) {
      const blob = this.dataURItoBlob(srcEncodedSmallNoAlpha);
      await imageRefSmallNoAlpha.put(blob, metaData);
    }
    const downloadURLSmallNoAlpha = await this.getImageUrl(imagePathSmallNoAlpha);

    // Medium image
    const imageLengthMedium = this.getImageLength(image, 1000);
    const uniqueFileNameMedium = `${this.uuidHelperService.getUUID()}.${fileExtension}`;
    const imagePathMedium = 'images/' + uniqueFileNameMedium;
    const imageRefMedium = this.storageRef.child(imagePathMedium);
    const canvasMedium = document.createElement('canvas');
    canvasMedium.width = imageLengthMedium;
    canvasMedium.height = imageLengthMedium;
    const contextMedium = canvasMedium.getContext('2d');
    contextMedium?.drawImage(image, left, top, shortSide, shortSide, 0, 0, canvasMedium.width, canvasMedium.height);
    const srcEncodedMedium = contextMedium?.canvas.toDataURL('image/' + fileExtension);
    if (srcEncodedMedium) {
      const blob = this.dataURItoBlob(srcEncodedMedium);
      await imageRefMedium.put(blob, metaData);
    }
    const downloadURLMedium = await this.getImageUrl(imagePathMedium);

    return new ImageRef(
      imagePathSmall,
      downloadURLSmall,
      imagePathMedium,
      downloadURLMedium,
      imagePathSmallNoAlpha,
      downloadURLSmallNoAlpha);
  }

  public async getImageUrl(imageId: string): Promise<string> {
    return this.storageRef.child(imageId).getDownloadURL().then( (downloadUrl) => {
      return downloadUrl;
    });
  }

  public deleteImage(imageRef: ImageRef): Promise<any> {

    const imageReferences: Array<Promise<any>> = [];

    if (imageRef.smallImageId) {
      imageReferences.push( this.storageRef.child(imageRef.smallImageId).delete());
    }

    if (imageRef.mediumImageId) {
      imageReferences.push(this.storageRef.child(imageRef.mediumImageId).delete());
    }

    return Promise.all( imageReferences );
  }

  private getImageLength(image: HTMLImageElement, maxlength: number): number {
    if (image.width > maxlength && image.height > maxlength) {
      return maxlength;
    }

    if (image.height < image.width ) {
      return image.height;
    }

    return image.width;
  }

  private loadImage(url: string): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.addEventListener('load', (e) => resolve(img));
      img.addEventListener('error', () => {
        reject(new Error(`Failed to load image's URL: ${url}`));
      });
      img.src = url;
    });
  }

  private blobToImage(blob: Blob): Promise<HTMLImageElement> {
    return new Promise((resolve) => {
      const url = URL.createObjectURL(blob);
      const img = new Image();
      img.onload = () => {
        URL.revokeObjectURL(url);
        resolve(img);
      };
      img.src = url;
    });
  }

  private dataURItoBlob(dataURI: string): Blob {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    const byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    const ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    const ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    return new Blob([ab], {type: mimeString});
  }
}
