import { IFirebase } from './IFirebase';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/auth';

export class Firebase implements IFirebase {

    private _firestore: firebase.firestore.Firestore;
    private _storage: firebase.storage.Storage;
    private _auth: firebase.auth.Auth;

    constructor() {

        const firebaseConfig = {
            apiKey: process.env.VUE_APP_FIREBASE_APIKEY,
            authDomain: process.env.VUE_APP_FIREBASE_AUTHDOMAIN,
            databaseURL: process.env.VUE_APP_FIREBASE_DATABASEURL,
            projectId: process.env.VUE_APP_FIREBASE_PROJECTID,
            storageBucket: process.env.VUE_APP_FIREBASE_STORAGEBUCKET,
            messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGINGSENDERID,
            appId: process.env.VUE_APP_FIREBASE_APPID,
            measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENTID
          };

        const firebaseApp = firebase.initializeApp(firebaseConfig);

        this._firestore = firebaseApp.firestore();

        this._storage = firebaseApp.storage();

        this._auth = firebaseApp.auth();
    }

    public get firestore(): firebase.firestore.Firestore {
        return this._firestore;
    }

    public get storage(): firebase.storage.Storage {
        return this._storage;
    }

    public get auth(): firebase.auth.Auth {
        return this._auth;
    }
}
