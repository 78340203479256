export class ImageRef {

    private _smallImageId: string;
    private _smallImageDownloadUrl: string;
    private _mediumImageId: string;
    private _mediumImageDownloadUrl: string;
    private _smallImageNoAlphaDownId: string;
    private _smallImageNoAlphaDownloadUrl: string;

    constructor(
        smallImageid: string,
        smallImagedownloadUrl: string,
        mediumImageid: string,
        mediumImagedownloadUrl: string,
        smallImageNoAlphaDownId: string,
        smallImageNoAlphaDownloadUrl: string) {
        this._smallImageId = smallImageid;
        this._smallImageDownloadUrl = smallImagedownloadUrl;
        this._mediumImageId = mediumImageid;
        this._mediumImageDownloadUrl = mediumImagedownloadUrl;
        this._smallImageNoAlphaDownId = smallImageNoAlphaDownId;
        this._smallImageNoAlphaDownloadUrl = smallImageNoAlphaDownloadUrl;
    }

    public get smallImageId(): string {
        return this._smallImageId;
    }

    public get smallImagedownloadUrl(): string {
        return this._smallImageDownloadUrl;
    }

    public get mediumImageId(): string {
        return this._mediumImageId;
    }

    public get mediumImagedownloadUrl(): string {
        return this._mediumImageDownloadUrl;
    }

    public get smallImageNoAlphaId(): string {
        return this._smallImageNoAlphaDownId;
    }

    public get smallImageNoAlphaDownloadUrl(): string {
        return this._smallImageNoAlphaDownloadUrl;
    }
}
