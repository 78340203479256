import { ImageRef } from './ImageRef';
import { ProductStatus } from './ProductStatus';
import { Category } from './Category';

export class Product {

    private _id: string;
    private _name: string;
    private _categoryId: string | undefined;
    private _category: Category | undefined;
    private _images: Map<number, ImageRef>;
    private _description: string;
    private _status: ProductStatus;
    private _nameUriformatted: string;
    private _price: number;
    private _createdTimeUnixEpochUTCSec: number;
    private _modifiedTimeUnixEpochUTCSec: number | undefined;

    constructor(
        id: string,
        name: string,
        nameUriformatted: string,
        categoryId: string | undefined,
        images: Map<number, ImageRef>,
        description: string,
        status: ProductStatus,
        price: number,
        category: Category | undefined,
        createdTimeUnixEpochUTCSec: number,
        modifiedTimeUnixEpochUTCSec: number | undefined) {

        this._name = name;
        this._nameUriformatted = nameUriformatted;
        this._id = id;
        this._categoryId = categoryId;
        this._images = images;
        this._description = description;
        this._status = status;
        this._price = price;
        this._category = category;
        this._createdTimeUnixEpochUTCSec = createdTimeUnixEpochUTCSec;
        this._modifiedTimeUnixEpochUTCSec = modifiedTimeUnixEpochUTCSec;
    }

    public get id(): string {
        return this._id;
    }

    public get name(): string {
        return this._name;
    }

    public get nameUriformatted(): string {
        if (this._nameUriformatted) {
            return this._nameUriformatted;
        } else {
            return this._id;
        }
    }

    public get images(): Map<number, ImageRef> {
        return this._images;
    }

    public get primaryImage(): ImageRef | undefined {
        if (!this._images || this._images.size === 0 ) {
            return undefined;
        } else {
            return this._images.get(0);
        }
    }

    public get categoryId(): string | undefined {
        return this._categoryId;
    }

    public get category(): Category | undefined {
        return this._category;
    }

    public get description(): string {
        return this._description;
    }

    public get status(): ProductStatus {
        return this._status;
    }

    public get price(): number {
        return this._price;
    }

    public get createdTimeLocalized(): string | undefined {
        if (!this._createdTimeUnixEpochUTCSec) {
            return undefined;
        }

        const date = new Date(0);
        date.setUTCSeconds(this._createdTimeUnixEpochUTCSec);

        return date.toLocaleDateString();
    }

    public get modifiedTimeLocalized(): string | undefined {
        if (!this._modifiedTimeUnixEpochUTCSec) {
            return undefined;
        }

        const date = new Date(0);
        date.setUTCSeconds(this._modifiedTimeUnixEpochUTCSec);

        return date.toLocaleDateString();
    }
}
