import { IUUIDHelperService } from './IUUIDHelperService';
import {v1} from 'uuid';

export class UUIDHelperService implements IUUIDHelperService  {

    public getUUID(): string {
        return v1();
    }

}
